@import "styles/app.scss";

.offerCard {
  min-height: 560px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  backdrop-filter: blur(10px);
  margin: 0 8px;
  padding: 16px 16px 30px 16px;
  &--light {
    background: rgba($colorWhite, 0.1);
    .infoText{

      color: $colorWhite;

      &__disabled {
        color: rgba(255, 255, 255, 0.4);
      }
    }
    .h2Subtitle {
      color: $colorWhite;
    }
    .article {
      color: $colorWhite;
    }
  }
  &--dark {
    background: rgba($colorLightGray, 0.5);
    .infoText{

      color: $colorAccent;

      &__disabled {
        color: colorGray;
      }
    }
    .h2Subtitle {
      color: $colorAccent;
    }
    .article {
      color: $colorAccent;
    }
  }

  .infoText {
    @include p45;
    margin-bottom: 10px;
  }


  .article {
    margin-top: 8px;
    margin-bottom: 40px;
  }

  .offerCardImgWrapper {
    position: relative;
    background-color: $colorAccent;
    width: 310px;
    height: 237px;

    img {
      width: 310px;
      height: 237px;
      min-width: 100%;
      object-fit: cover;
      aspect-ratio: 310 / 237;
    }
  }

  .descriptionWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 20px;
  }

  .descriptionDown {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    a {
      white-space: nowrap;
    }
  }
  .buttonWrapper {
    height: 100%;
    display: flex;
    align-items: flex-end;
  }
}
@include mediaSm {
  .offerCard {
    flex: 0 0 50%;
  }
}
@media (min-width: $screenLg) {
  .offerCard {
    display: flex;
    flex-direction: row;
    height: auto;
    width: 99.5%;
    min-height: unset;
    gap: 50px;
    flex: 0 0 90%;

    &:first-of-type {
      margin-left: 0px;
    }
    .article {
      margin-top: 10px;
      margin-bottom: 0;
      padding-right: 20px;
    }

    .offerCardImgWrapper {
      width: 405px;
      height: 100%;
      img {
        width: 405px;
        height: auto;
        aspect-ratio: 405 / 405;
      }
    }

    .descriptionWrapper {
      padding: 42px 0px 20px;
    }
    .buttonWrapper {
      padding-bottom: 15px;
    }
  }
}

@media (min-width: $screenGt) {
  .offerCard {
    .offerCardImgWrapper {
      width: 532px;
      height: 100%;
      img {
        width: 532px;
        height: 100%;
        aspect-ratio: 532 / 405;
      }
    }
  }
}

@media (min-width: 1400px) {
  .offerCard {
    padding: 20px 53px 20px 20px;
    flex: 0 0 85%;
    .descriptionWrapper {
      padding: 42px 0px 20px;
    }

    .descriptionDown {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      gap: 10px;
    }
  }
}

@media (min-width: $screenXx) {
  .offerCard {
    height: 455px;

    .offerCardImgWrapper {
      height: 100%;
      img {
        height: 100%;
        aspect-ratio: 532 / 455;
      }
    }
    .descriptionWrapper {
      padding: 42px 30px 20px 0px;
    }
    .descriptionDown {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      gap: 15px;
    }
  }
}
