@import "styles/app.scss";

.tooltip {
  padding: 10px;
  max-width: 260px;
  border-radius: 4px;
  z-index: 10;
  @include p4;
}

.tooltipBtn {
  position: relative;
}