@import "styles/app.scss";

.card {
  position: relative;
  width: 100%;
  &--youtube {
    max-width: 343px;
    height: 190px;
  }
  &--insta {
    max-width: 343px;
    height: 309px;
  }

  @include mediaLg {
    &--insta {
      max-width: 343px;
      height: 190px;
    }
  }

  &__play {
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    backdrop-filter: blur(4px);
    box-shadow: 0px 51px 64px 0px #00000040;
    pointer-events: none;
    touch-action: none;
  }
}
.defaultCard {
  position: relative;
  width: 100%;
  max-width: 343px;
  height: 190px;
  background-color: white;
  padding: 20px 16px;

  &:hover stop {
    &:first-child {
      stop-color: #7ea2bc;
      offset: 0;
    }
    &:last-child {
      offset: 1;
      stop-color: #4072a3;
    }
  }

  &__bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__text {
    width: 50%;
  }

  &__textInsta {
    width: 50%;
    @include mediaGt {
      flex: 1;
    }
  }

  &__arrow {
    width: 40px;
    height: 40px;
    flex: none;
    display: flex;
    align-items: center;
    justify-content: center;
    stop {
      &:first-child {
        stop-color: $colorAccent;
      }
      &:last-child {
        stop-color: $colorAccent;
      }
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    &:hover p {
      @include bgGradient277;
    }
    p {
      @include hover-gradient-text;
    }
  }
}

@include mediaXx {
  .card {
    &--youtube {
      max-width: 392px;
      height: 230px;
    }

    &--insta {
      max-width: 255px;
      height: 230px;
    }
  }

  .defaultCard {
    max-width: 392px;
      height: 230px;
  }
}
