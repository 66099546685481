@import "styles/app.scss";

.card {
  max-width: 1260px; 
  @include bgWhite10;
  margin-left: 8px;
  margin-right: 8px;
  padding: 8px 8px 30px 8px;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  color: $colorWhite;
  flex: 0 0 100%;
  opacity: 0.3;
  transition: opacity 0.2s;
  &:is(:global(.is-snapped)) {
    opacity: 1;
  }
  @include mediaMd {
    padding: 16px 22px 32px 16px;
    flex: 0 0 70%;
  }
  &__left {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 20px;
  }
  &__primary {
    width: 100%;
    object-fit: cover;
  }
  &__info {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  &__description {
    margin-top: 10px;
    margin-bottom: 18px;
  }
  &__district {
    display: flex;
    gap: 11px;
    margin-top: auto;
    flex-grow: 1;
    align-items: flex-end;
  }
  &__divider {
    background-color: $colorDarkWhite;
    min-height: 1px;
    width: 100%;
    margin-top: auto;
  }
  &__price {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 18px;
    align-items: flex-start;
    text-transform: capitalize;
    > h3 {
      white-space: nowrap;
    }
    @include mediaMd {
      flex-direction: row;
      align-items: center;
    }
  }
  &__order {
    width: 100%;
    @include mediaSm {
      width: auto;
    }
  }
  &__right {
    height: 100%;
    max-height: 558px;
    display: none;
    @include mediaGt {
      display: block;
    }
  }
  &__secondary {
    object-fit: cover;
    height: 100%;
    width: 100%;
    min-width: 364px;
  }
}
