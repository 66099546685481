@import "styles/app.scss";
.card {
  text-decoration: none;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(15px);
  width: 100%;
  margin-top: 8px;
  padding: 30px 20px;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  @include bgGradient135Transition(".linkButton");

  .h3TitleText {
    color: $colorAccent;
    margin-bottom: 16px;
    @include bgGradient135Text($colorAccent);
  }

  .buttonContainer {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  .text {
    color: $colorGray;
    max-width: 224px;
  }

  .linkButton {
    flex: none;
    overflow: hidden;
    transition: background 0.2s;

    .linkButton_icon {
      position: absolute;
      margin: auto;
      z-index: 2;
    }
  }

  &:hover {
    .h3TitleText {
      color: rgba($colorAccent, 0);
    }
    .linkButton {
      border: none;

      transition:
        background 0.2s,
        border 0.2s;

      .linkButton_icon {
        color: $colorWhite;
      }
    }
  }
}

@media (min-width: $screenLg) {
  .card {
    padding: 42px 24px 30px;
    height: 300px;
    margin: 0;

    .buttonContainer {
      flex-direction: column;
      align-items: flex-start;
      flex: 1;
    }

    .h3TitleText {
      max-width: 260px;
      margin-bottom: 28px;
    }

    .text {
      max-width: 270px;
    }
  }
}

@media (min-width: $screenGt) {
  .card {
    padding-inline: 32px;
  }
}

@media (min-width: $screenXx) {
  .card {
    padding: 51px 39px 36px;
    height: 362px;
  }
}
