@import "styles/app.scss";

.review {
  width: 98%;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  padding: 16px 16px 28px;
  margin-top: 20px;
  min-height: 425px;
  color: $colorWhite;    
  flex: 0 0 100%;
  margin: 0 20px;
  
  &__top {
    display: flex;

    img {
      width: 58px;
      height: 58px;
      border-radius: 50%;
    }
  }

  &__inner {
    padding-left: 20px;
  }

  &__divider {
    border-bottom: 1px solid $colorWhite;
    opacity: 0.7;
    margin: 20px 0 28px;
    width: 100%;
  }

  @include mediaEx {
    padding: 40px 28px;
    min-height: 360px;

    &__top {
      img {
        width: 88px;
        height: 88px;
      }

      div {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }
    }

    &__divider {
      margin: 28px 0;
    }
  }

  @include mediaMin(425px) {
    min-height: 367px;
  }
  @include mediaSm{
    min-height: 300px;
  }
  @include mediaMd {
    min-height: 280px;
  }
  @include mediaLg {
    margin-right: 15px;
    margin-left: 15px;
    min-height: 320px; 
    flex: 0 0 48%;
  }
  @include mediaGt {
    min-height: 297px;
  }
}
